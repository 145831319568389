html, body { height: 100%; width: 100%; margin: 0; }

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Webcam */
#Webcam{
  position: fixed;
  background-color: white;
  padding: 10px;
  /* width: 80%; */
  top: 20%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


#close-cam{
  cursor: pointer;
  position: absolute;
  right: 10px;
  font-size: 15px;
  background-color:transparent;
  color:white;
  border:none;
  font-size:'20px'
}

/* Toast */
#snackbar {
  /* visibility: hidden; */
  width: 100%;
  background-color: red;
  color: #fff;
  padding: 15px;
  position: fixed;
  z-index: 1;
  bottom: 0px;
  font-size: 17px;
}

#snackbar button{
  position: relative;
  font-size:20px;
  color:white;
  float:right;
  right:20px;
  height:100%;
  background-color: transparent;
  border: none
}

#guide{
  position: fixed;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  top: 0%;
  left: 0%;
}

#guide-content{
  background-color: #282c34;
  margin: 5% auto;
  width: 50%;
  padding: 20px;
}

#guide-close{
  float: right;
  top: 0;
  right: 0;
  background-color: transparent;
  border: none;
  color:white;
  cursor:pointer;
}

#guide-open{
  background-color: transparent;
  border: none;
  position: fixed;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
}

/* Collapsible */

.Collapsible {
  cursor: pointer;
  margin: 15px 0;
  text-align: justify;
}

.Collapsible__trigger {
  text-decoration: underline;
}

/* Centered text */
.centered {
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.centered p{
  font-size: 80px;
}


body {
    overflow-x: hidden;
    overflow-y: hidden;
    box-sizing: border-box;
    background: #313130;
}

#robot-body{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#robot{
  position:relative;
  width: 450px;
  height: 450px;
}

.book{
    position:absolute;
    width: 60%;
    left: 20%;
    top: 40%;
    display: none;
    z-index: 100;
    animation: 1s both;
}

.headset{
    position: absolute;
    bottom: 10%;
    animation: 5s cubic-bezier(0.785, 0.135, 0.15, 0.86) forwards;
}

#wrap {
    color: #dbc9aa;
    position: absolute;
    height: 30%;
    width: 60%;
    top: 30%;
    left: 20%;
    animation: 5s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
#wrap .eye {
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    background: #fff;
    width: 45%;
    height: 80%;
    border-radius: 15%;
    display: inline-block;
    overflow: hidden;
    animation: 5s cubic-bezier(0.785, 0.135, 0.15, 0.86) forwards;
}

#wrap .eye:first-child {
    left: 0;
}
#wrap .eye:last-child {
    right: 0;
}

@keyframes blink {
    0%, 8% {
        height: 80%;
   }
    10%, 12% {
        height: 5%;
   }
    14%, 100% {
        height: 80%;
   }
}

@keyframes close-eyes {
    0%, 10% {
        height: 80%;
   }
    20%, 40% {
        height: 40%;
   }
    50%, 100% {
        height: 5%;
   }  
}

@keyframes open-eyes {
    0%, 10% {
        height: 5%;
   }
    20%, 40% {
        height: 40%;
   }
    50%, 100% {
        height: 80%;
    }
}

@keyframes double-blink {
    0%, 8% {
        height: 80%;
   }
    10%, 12% {
        height: 5%;
   }
    13% {
        height: 80%;
   }
    14%, 16% {
        height: 5%;
   }
    18%, 100% {
        height: 80%;
   }
}
@keyframes top {
    0%, 5% {
        top: 30%;
   }
    10%, 40% {
        top: 25%;
   }
    50%, 100% {
        top: 30%;
   }
}

@keyframes bottom {
    0%, 10% {
        top: 30%;
   }
    20%, 70% {
        top: 35%;
   }
    80%, 100% {
        top: 30%;
   }
}

@keyframes right {
    0%, 10% {
        left: 20%;
   }
    20%, 70% {
        left: 23%;
   }
    80%, 100% {
        left: 20%;
   }
}

@keyframes left {
    0%, 10% {
        left: 20%;
   }
    20%, 70% {
        left: 17%;
   }
    80%, 100% {
        left: 20%;
   }
}

@keyframes done-read{
    0%,10%{
        top: 33%
    }
    30%,100%{
        top: 30%;
    }
}

@keyframes read {
  0%, 10% {
    left: 20%;
  }
  20%, 50% {
    left: 23%;
  }
  50%, 80%{
    left: 17%;
  }
  90%, 100%{
    left: 20%;
  }    
}


@keyframes eyes-when-read{
  0%,10%{
    top: 0;
  }
  10%,100%{
    top: 25%;
  }
}


@keyframes eyes-when-done-read{
  0%,10%{
    top: 25%;
  }
  10%,100%{
    top: 0;
  }
}


@keyframes eyes-top{
  0%,20%{
    top: 0%;
  }
  30%,100%{
    top: 20%;
  }
}

@keyframes take-off-headset{
    0%, 10% {
        bottom: 10%;
   }
    30%, 100% {
        bottom: 200%;
   }
}

@keyframes take-on-headset{
    0%, 10% {
        bottom: 200%;
   }
    30%, 100% {
        bottom: 10%;
   }
}

@keyframes fadeInBottom {
    from {
        opacity: 0;
        transform: translateY(100%);
    }
    to { opacity: 1 }
}


@keyframes fadeOutBottom {
    from { opacity: 1 }
    to {
        opacity: 0;
        transform: translateY(100%);
    }
}

/* Sleeping */
#sleeping {
  width: 50%;
  height: 50%;
  position: absolute;
  top: 30%;
  left: 80%;
  display: none;
  transform: translate(-50%, -50%);
}
#sleeping span {
  color: #fff;
  position: absolute;
  bottom: 10%;
  left: 10%;
  font-size: 200px;
  opacity: 0;
  animation: sleep 9s infinite linear;
}
#sleeping span:nth-child(2n) {
  animation-delay: 3s;
}
#sleeping span:nth-child(3n) {
  animation-delay: 6s;
}

@-webkit-keyframes sleep {
  0% {
    transform: translate(0, 0) scale(0.3);
    opacity: 0;
  }
  1% {
    opacity: 0;
  }
  3% {
    opacity: 1;
  }
  50% {
    transform: translate(90%, -50%) scale(0.65);
  }
  75% {
    opacity: 1;
  }
  100% {
    transform: translate(180%, -100%) scale(1);
    opacity: 0;
  }
}
@-moz-keyframes sleep {
  0% {
    transform: translate(0, 0) scale(0.3);
    opacity: 0;
  }
  1% {
    opacity: 0;
  }
  3% {
    opacity: 1;
  }
  50% {
    transform: translate(90%, -50%) scale(0.65);
  }
  75% {
    opacity: 1;
  }
  100% {
    transform: translate(180%, -100%) scale(1);
    opacity: 0;
  }
}
@-ms-keyframes sleep {
  0% {
    transform: translate(0, 0) scale(0.3);
    opacity: 0;
  }
  1% {
    opacity: 0;
  }
  3% {
    opacity: 1;
  }
  50% {
    transform: translate(90%, -50%) scale(0.65);
  }
  75% {
    opacity: 1;
  }
  100% {
    transform: translate(180%, -100%) scale(1);
    opacity: 0;
  }
}
@keyframes sleep {
  0% {
    transform: translate(0, 0) scale(0.3);
    opacity: 0;
  }
  1% {
    opacity: 0;
  }
  3% {
    opacity: 1;
  }
  50% {
    transform: translate(90%, -50%) scale(0.65);
  }
  75% {
    opacity: 1;
  }
  100% {
    transform: translate(180%, -100%) scale(1);
    opacity: 0;
  }
}


@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  #robot{
    width: 300px;
    height: 300px;
  }
   
  #guide-content{
    width: 80%;
  }
}